<template>
  <div>
    <p class="mb-5">
      Click the buttons below to construct the proper
      <span class="text-bold">full electron configuration</span>
      for a neutral atom of:
    </p>
    <p class="mb-3">a) <chemical-latex :content="atom1Name" /></p>
    <v-form class="mb-10" @submit.prevent="submitResponse">
      <electron-configuration-input
        v-model="inputs.input1"
        :max-n="4"
        :max-occupation="10"
        :orbital-types="['s', 'p', 'd']"
      />
    </v-form>
    <p class="mb-3">b) <chemical-latex :content="atom2Name" /></p>
    <v-form @submit.prevent="submitResponse">
      <electron-configuration-input
        v-model="inputs.input2"
        :max-n="4"
        :max-occupation="10"
        :orbital-types="['s', 'p', 'd']"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ElectronConfigurationInput from '../inputs/ElectronConfigurationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question201',
  components: {
    ElectronConfigurationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: [],
      },
    };
  },
  computed: {
    chemicalElement1() {
      return this.taskState.getValueBySymbol('element1').content;
    },
    atom1Name() {
      return this.chemicalElement1.name;
    },
    chemicalElement2() {
      return this.taskState.getValueBySymbol('element2').content;
    },
    atom2Name() {
      return this.chemicalElement2.name;
    },
  },
};
</script>
