<template>
  <div class="text-left pl-3">
    <stemble-latex
      :class="{'text-muted': !hasValue}"
      class="mb-3 pl-5 d-inline-block font-weight-bold"
      :content="latexString"
    />

    <div class="mb-2 pl-5">
      <span class="d-inline-block mr-3">Orbital Type:</span>
      <br />
      <v-btn-toggle>
        <v-row :dense="true" align="start">
          <v-col
            v-for="availableOrbital in computedOrbitals"
            :key="availableOrbital"
            cols="auto"
            class="pa-1"
          >
            <v-btn
              class="no-uppercase"
              style="font-size: 0.9em"
              small
              color="primary"
              :disabled="inputtingOccupation"
              @click="inputOrbital(availableOrbital)"
            >
              {{ availableOrbital }}
            </v-btn>
          </v-col>
        </v-row>
      </v-btn-toggle>
    </div>
    <div class="pl-5">
      <span class="d-inline-block mr-3">Occupation:</span>
      <br />
      <v-btn-toggle class="pl-1">
        <v-row :dense="true" align="start">
          <v-col
            v-for="i in computedMaxOccupation + (includeZero ? 1 : 0)"
            :key="i"
            cols="auto"
            class="pa-1"
          >
            <v-btn
              style="font-size: 0.9em"
              small
              color="primary"
              :disabled="inputtingOrbital"
              @click="inputOccupation(i - (includeZero ? 1 : 0))"
            >
              {{ i - (includeZero ? 1 : 0) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-btn-toggle>
    </div>
    <div class="pl-2">
      <v-btn
        id="no-background-backspace-button"
        icon
        large
        color="error"
        :disabled="!hasValue"
        @click="backspace"
      >
        <v-icon dark> mdi-backspace </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex';

const ORBITAL_TYPES = ['s', 'p', 'd', 'f', 'g', 'h', 'i', 'j', 'k'];

export default {
  name: 'ElectronConfigurationInput',
  components: {StembleLatex},
  props: {
    value: Array,
    maxN: {
      type: Number,
      default: null,
    },
    maxOccupation: {
      type: Number,
      default: null,
    },
    availableOrbitals: {
      type: Array,
      default: null,
    },
    orbitalTypes: {
      type: Array,
      default: null,
    },
    includeZero: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Click an orbital type to begin',
    },
    prefix: String,
  },
  computed: {
    hasValue() {
      return this.value.length;
    },
    parsedFragments() {
      return this.value.map((fragment) => this.parseFragment(fragment));
    },
    inputtingOrbital() {
      const lastFragment = this.parsedFragments[this.parsedFragments.length - 1];
      return lastFragment ? this.fragmentHasOccupation(lastFragment) : true;
    },
    inputtingOccupation() {
      return !this.inputtingOrbital;
    },
    computedOrbitals() {
      if (this.availableOrbitals !== null && this.maxOccupation !== null) {
        return this.availableOrbitals;
      } else if (this.maxN) {
        const orbitals = [];
        const orbitalTypes = this.orbitalTypes || ORBITAL_TYPES;
        for (let i = 1; i <= this.maxN; i++) {
          for (let j = 0; j < orbitalTypes.length; j++) {
            if (i > j) {
              orbitals.push(`${i}${orbitalTypes[j]}`);
            } else {
              break;
            }
          }
        }
        return orbitals;
      } else {
        return [];
      }
    },
    computedMaxOccupation() {
      if (this.maxOccupation !== null) {
        return this.maxOccupation;
      } else if (this.maxN) {
        return 2 + 4 * (this.maxN - 1);
      } else {
        return 0;
      }
    },
    latexString() {
      let result = this.prefix ? this.prefix : '';
      for (const {occupation, orbital} of this.parsedFragments) {
        result += `{${orbital}}`;
        if (occupation !== null) {
          result += `^{${occupation}}`;
        }
        result += ' ';
      }
      return result !== '' ? `$${result}$` : this.placeholder;
    },
  },
  methods: {
    parseFragment(fragment) {
      const splits = fragment.split(/^(\d+[a-z])(?=\d+)?/, 3);
      return {
        occupation: splits[2] !== '' ? parseInt(splits[2]) : null,
        orbital: splits[1],
      };
    },
    fragmentHasOccupation(fragment) {
      return fragment.occupation !== null;
    },
    inputOrbital(orbital) {
      const result = [...this.value];
      result.push(orbital);
      this.$emit('input', result);
    },
    inputOccupation(occupation) {
      const result = [...this.value];
      if (result.length !== 0) {
        result[result.length - 1] = result[result.length - 1] + occupation.toString();
        this.$emit('input', result);
      }
    },
    backspace() {
      if (this.value.length === 0) {
        return;
      }

      if (this.inputtingOccupation) {
        const result = [...this.value];
        result.pop();
        this.$emit('input', result);
      } else {
        const result = [...this.value];
        result[result.length - 1] = this.parseFragment(result[result.length - 1])['orbital'];
        this.$emit('input', result);
      }
    },
  },
};
</script>
<style>
#no-background-backspace-button {
  background-color: transparent;
}
v-icon {
  margin: 12px 0 !important;
}

.v-btn.no-uppercase {
  text-transform: none;
}
</style>
